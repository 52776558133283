//静态路由
export const constantRouter = [
  {
    path: "/largedata",
    name: "LargeData",
    component: () => import("@/views/LargeData"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login"),
  },
  {
    path: "/",
    name: "layout",
    component: () => import("@/views/Layout"),
    redirect: "/home",
    children: [
      //首页
      {
        path: "/home",
        name: "Home",
        component: () => import("@/views/Home"),
      },
    ],
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404"),
    hidden: true,
  },
  {
    path: "*",
    redirect: "/404",
    hidden: true,
  },
];

//任意路由 路径出错重定向404
export const anyRouter = [{ path: "*", redirect: "/404", hidden: true }];

//全部路由
export const routes1 = [
  {
    path: "/audit",
    name: "Audit",
    component: () => import("@/views/Audit.vue"),
  },
  {
    path: "/largedata",
    name: "LargeData",
    component: () => import("@/views/LargeData"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login"),
  },
  {
    path: "/",
    name: "layout",
    component: () => import("@/views/Layout"),
    redirect: "/home",
    children: [
      //首页
      {
        path: "/home",
        name: "Home",
        component: () => import("@/views/Home"),
      },
    ],
  },
  {
    title: "人员管理",
    path: "/pm",
    component: () => import("@/views/Layout"),
    icon: "el-icon-user",
    children: [
      {
        name: "userinfo",
        title: "用户信息",
        path: "userinfo",
        component: () => import("@/views/PersonnelManage/UserInfo"),
        meta: {
          keepAlive: true,
        },
      },
      {
        title: "清运员信息",
        path: "qyinfo",
        component: () => import("@/views/PersonnelManage/QyInfo"),
      },
      {
        title: "转运员信息",
        path: "zyinfo",
        component: () => import("@/views/PersonnelManage/ZyInfo"),
      },
      {
        title: "分拣员信息",
        path: "fjinfo",
        component: () => import("@/views/PersonnelManage/FjInfo"),
      },
      {
        title: "审查员信息",
        path: "scinfo",
        component: () => import("@/views/PersonnelManage/ScInfo"),
      },
      {
        title: "",
        path: "userdetail",
        component: () => import("@/views/PersonnelManage/UserDetail"),
      },
    ],
  },
  {
    title: "订单管理",
    path: "/om",
    component: () => import("@/views/Layout"),
    icon: "el-icon-tickets",
    children: [
      {
        title: "投放订单",
        path: "delivery",
        component: () => import("@/views/OrdersManage/Delivery"),
      },
      {
        title: "订单审核",
        path: "audit",
        component: () => import("@/views/OrdersManage/Audit"),
      },
      {
        title: "清运订单",
        path: "omremoval",
        component: () => import("@/views/OrdersManage/OmRemoval"),
      },
      {
        title: "转运订单",
        path: "omtranshipment",
        component: () => import("@/views/OrdersManage/OmTranshipment"),
      },
      {
        title: "上门订单",
        path: "door",
        component: () => import("@/views/OrdersManage/Door"),
      },
      {
        title: "问题订单",
        path: "problem",
        component: () => import("@/views/OrdersManage/Problem"),
      },{
        title: "问题上报",
        path: "report",
        component: () => import("@/views/OrdersManage/Report"),
      },
    ],
  },
  {
    title: "设备管理",
    path: "/em",
    component: () => import("@/views/Layout"),
    icon: "el-icon-set-up",
    children: [
      {
        title: "设备信息",
        path: "information",
        component: () => import("@/views/EquipmentManage/Information"),
      },
    ],
  },
  {
    title: "运营管理",
    path: "/operationmanage",
    component: () => import("@/views/Layout"),
    icon: "el-icon-data-line",
    children: [
      {
        title: "设备运营",
        path: "equipment",
        component: () => import("@/views/OperationManage/Equipment"),
      },
      {
        title: "清运运营",
        path: "removal",
        component: () => import("@/views/OperationManage/Removal"),
      },
      {
        title: "转运运营",
        path: "transhipment",
        component: () => import("@/views/OperationManage/Transhipment"),
      },
      {
        title: "推广运营",
        path: "equipment1",
        component: () => import("@/views/OperationManage/Equipment1"),
      },
      {
        title: "活动排名",
        path: "rankactive",
        component: () => import("@/views/OperationManage/RankActive"),
      },
    ],
  },
  {
    title: "上门回收",
    path: "/reclaim",
    component: () => import("@/views/Layout"),
    icon: "el-icon-truck",
    children: [
      {
        title: "回收物品",
        path: "goods",
        component: () => import("@/views/Reclaim/Goods"),
      },
      {
        title: "回收分类",
        path: "classify",
        component: () => import("@/views/Reclaim/Classify"),
      },
    ],
  },
  {
    title: "财务管理",
    path: "/fm",
    component: () => import("@/views/Layout"),
    icon: "el-icon-price-tag",
    children: [
      {
        title: "用户账户",
        path: "useraccount",
        component: () => import("@/views/FinanceManage/UserAccount"),
      },
      {
        title: "清运员账户",
        path: "cleanersaccount",
        component: () => import("@/views/FinanceManage/CleanersAccount"),
      },
      {
        title: "公司账户",
        path: "firmaccount",
        component: () => import("@/views/FinanceManage/FirmAccount"),
      },
      {
        title: "回收箱资金动向",
        path: "boxaccount",
        component: () => import("@/views/FinanceManage/BoxAccount"),
      },
    ],
  },
  {
    title: "系统管理",
    path: "/admin",
    component: () => import("@/views/Layout"),
    icon: "el-icon-set-up",
    children: [
      {
        title: "权限管理",
        path: "permissions-admin",
        component: () => import("@/views/Administration/PermissionsAdmin"),
      },
      {
        title: "用户管理",
        path: "user-admin",
        component: () => import("@/views/Administration/UserAdmin"),
      },
      {
        title: "参数设置",
        path: "parameter",
        component: () => import("@/views/Administration/Parameter"),
      },
      {
        title: "系统日志",
        path: "log",
        component: () => import("@/views/Administration/Log"),
      },
      {
        title:'客服审核记录',
        path:'customer',
        component: () => import('@/views/Administration/Customer')
      },
      {
        title:'问题与建议发聩',
        path:'userfeedback',
        component: () => import('@/views/Administration/UserFeedback')
      }
    ],
  },
];
