import VueRouter from "vue-router";
import Vue from "vue";
import {
  getUserInfo,
  removeUserInfo
} from "@/utils/userInfo";
import { routes1, constantRouter } from "@/router/routes";

Vue.use(VueRouter);

// const router = new VueRouter({
//   //路由滚动配置
//   scrollBehavior(to, from, savedPosition) {
//     // y=0代表滚动条为在最顶部
//     return { y: 0 };
//   },
//   routes: routes1,
// });

const createRouter = () =>
  new VueRouter({
    //路由滚动配置
    scrollBehavior(to, from, savedPosition) {
      // y=0代表滚动条为在最顶部
      return { y: 0 };
    },
    routes: routes1,
  });

const router = createRouter();

router.beforeEach((to, from, next) => {
  if (!getUserInfo() && to.path !== "/login") {
    next("/login");
  } else if (to.path == "/login") {
    removeUserInfo()
    next();
  } else {
    next();
  }
});

//解决重复登录时重复添加路由 或者 高级权限改低级权限时 某些路由已经注入的问题
// export function resetRouter() {
//   const newRouter = createRouter();
//   router.matcher = newRouter.matcher;
// }

// router.beforeEach((to, form, next) => {
//   //如果进入到的路由是登录页或者注册页面，则正常展示
//   let flag = getToken();
//   //判断是否有token
//   if (to.path == "/") {
//     if (flag == "" || flag == null) {
//       next();
//     } else {
//       next("/");
//     }
//   } else {
//     if (router.getRoutes().length <= 5) {
//       //清空路由防止重复添加
//       resetRouter();
//       //动态添加路由
//       getRouter().forEach((route) => {
//         router.addRoute(route);
//       });
//       //跳转 replace: true 不添加到历史记录
//       next({ ...to, replace: true });
//     } else {
//       // 否则直接跳转
//       next();
//     }
//   }
// });

export default router;
