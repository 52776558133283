// export default [
//   { title: "首页", path: "/home", component: "Home", icon: "el-icon-menu" },
//   {
//     title: "人员管理",
//     path: "/pm",
//     component: "Layout",
//     icon: "el-icon-user",
//     children: [
//       { title: "用户信息", path: "/pm/userinfo", component: "UserInfo" },
//       { title: "清运员信息", path: "/pm/qyinfo", component: "QyInfo" },
//       { title: "转运员信息", path: "/pm/zyinfo", component: "ZyInfo" },
//       { title: "分拣员信息", path: "/pm/fjinfo", component: "FjInfo" },
//       { title: "审查员信息", path: "/pm/scinfo", component: "ScInfo" },
//       { title: "", path: "/pm/userdetail", component: "UserDetail" },
//     ],
//   },
//   {
//     title: "订单管理",
//     path: "/om",
//     component: "Layout",
//     icon: "el-icon-tickets",
//     children: [
//       { title: "投放订单", path: "/om/delivery", component: "Delivery" },
//       { title: "订单审核", path: "/om/audit", component: "Audit" },
//       { title: "清运订单", path: "/om/removal", component: "Removal" },
//       {
//         title: "转运订单",
//         path: "/om/transhipment",
//         component: "Transhipment",
//       },
//       { title: "上门订单", path: "/om/door", component: "Door" },
//     ],
//   },
//   {
//     title: "设备管理",
//     path: "/em",
//     component: "Layout",
//     icon: "el-icon-set-up",
//     children: [
//       { title: "设备信息", path: "/em/information", component: "Information" },
//     ],
//   },
//   {
//     title: "运营管理",
//     path: "/operationmanage",
//     component: "Layout",
//     icon: "el-icon-data-line",
//     children: [
//       {
//         title: "设备运营",
//         path: "/operationmanage/equipment",
//         component: "Equipment",
//       },
//       {
//         title: "清运运营",
//         path: "/operationmanage/removal",
//         component: "Removal",
//       },
//       {
//         title: "转运运营",
//         path: "/operationmanage/transhipment",
//         component: "Transhipment",
//       },
//     ],
//   },
//   {
//     title: "上门回收",
//     path: "/reclaim",
//     component: "Layout",
//     icon: "el-icon-truck",
//     children: [
//       { title: "回收物品", path: "/reclaim/goods", component: "Goods" },
//       { title: "回收分类", path: "/reclaim/classify", component: "Classify" },
//     ],
//   },
//   {
//     title: "财务管理",
//     path: "/fm",
//     component: "Layout",
//     icon: "el-icon-price-tag",
//     children: [
//       { title: "用户账户", path: "/fm/useraccount", component: "UserAccount" },
//       {
//         title: "清运员账户",
//         path: "/fm/cleanersaccount",
//         component: "CleanersAccount",
//       },
//       { title: "公司账户", path: "/fm/firmaccount", component: "FirmAccount" },
//       {
//         title: "回收箱资金动向",
//         path: "/fm/boxaccount",
//         component: "BoxAccount",
//       },
//     ],
//   },
//   {
//     title: "系统管理",
//     path: "/admin",
//     component: "Layout",
//     icon: "el-icon-set-up",
//     children: [
//       {
//         title: "权限管理",
//         path: "/admin/permissions-admin",
//         component: "PermissionsAdmin",
//       },
//       {
//         title: "用户管理",
//         path: "/admin/user-admin",
//         component: "UserAdmin",
//       },
//       { title: "参数设置", path: "/admin/parameter", component: "Parameter" },
//       {
//         title: "系统日志",
//         path: "/admin/log",
//         component: "Log",
//       },
//     ],
//   },
// ];

// routes: [
//   {
//     path: "/login",
//     name: "Login",
//     component: Login,
//   },
//   {
//     path: "/",
//     component: Layout,
//     redirect: "/home",
//     children: [
//       //首页
//       {
//         path: "/home",
//         name: "Home",
//         component: Home
//       },
//     ],
//   },

//   //人员管理
//   {
//     path: "/pm",
//     name: "PersonnelManage",
//     component: () => import("@/views/PersonnelManage"),
//     children: [
//       {
//         path: "userinfo",
//         name: "UserInfo",
//         component: () => import("@/views/PersonnelManage/UserInfo"),
//       },
//       {
//         path: "userdetail",
//         name: "UserDetail",
//         component: () => import("@/views/PersonnelManage/UserDetail"),
//       },
//       {
//         path: "qyinfo",
//         name: "QyInfo",
//         component: () => import("@/views/PersonnelManage/QyInfo"),
//       },
//       {
//         path: "zyinfo",
//         name: "ZyInfo",
//         component: () => import("@/views/PersonnelManage/ZyInfo"),
//       },
//       {
//         path: "fjinfo",
//         name: "FjInfo",
//         component: () => import("@/views/PersonnelManage/FjInfo"),
//       },
//       {
//         path: "scinfo",
//         name: "ScInfo",
//         component: () => import("@/views/PersonnelManage/ScInfo"),
//       },
//     ],
//   },
//   //订单管理
//   {
//     path: "/om",
//     name: "OrdersManage",
//     component: () => import("@/views/OrdersManage"),
//     children: [
//       {
//         path: "delivery",
//         name: "Delivery",
//         component: () => import("@/views/OrdersManage/Delivery"),
//       },
//       {
//         path: "audit",
//         name: "Audit",
//         component: () => import("@/views/OrdersManage/Audit"),
//       },
//       {
//         path: "removal",
//         name: "Removal",
//         component: () => import("@/views/OrdersManage/Removal"),
//       },
//       {
//         path: "transhipment",
//         name: "transhipment",
//         component: () => import("@/views/OrdersManage/Transhipment"),
//       },
//       {
//         path: "door",
//         name: "Door",
//         component: () => import("@/views/OrdersManage/Door"),
//       },
//     ],
//   },
//   //设备管理
//   {
//     path: "/em",
//     name: "EquipmentManage",
//     component: () => import("@/views/EquipmentManage"),
//     children: [
//       {
//         path: "information",
//         name: "Information",
//         component: () => import("@/views/EquipmentManage/Information"),
//       },
//     ],
//   },
//   //运营管理
//   {
//     path: "/operationmanage",
//     name: "Operation",
//     component: () => import("@/views/OperationManage"),
//     children: [
//       {
//         path: "equipment",
//         name: "Equipment",
//         component: () => import("@/views/OperationManage/Equipment"),
//       },
//       {
//         path: "removal",
//         name: "Removal",
//         component: () => import("@/views/OperationManage/Removal"),
//       },
//       {
//         path: "transhipment",
//         name: "Transhipment",
//         component: () => import("@/views/OperationManage/Transhipment"),
//       },
//     ],
//   },
//   //上门回收
//   {
//     path: "/reclaim",
//     name: "Reclaim",
//     component: () => import("@/views/Reclaim"),
//     children: [
//       {
//         path: "goods",
//         name: "Goods",
//         component: () => import("@/views/Reclaim/Goods"),
//       },
//       {
//         path: "classify",
//         name: "Classify",
//         component: () => import("@/views/Reclaim/Classify"),
//       },
//     ],
//   },
//   //财务管理
//   {
//     path: "/fm",
//     name: "FinanceManage",
//     component: () => import("@/views/FinanceManage"),
//     children: [
//       {
//         path: "useraccount",
//         name: "UserAccount",
//         component: () => import("@/views/FinanceManage/UserAccount"),
//       },
//       {
//         path: "cleanersaccount",
//         name: "CleanersAccount",
//         component: () => import("@/views/FinanceManage/CleanersAccount"),
//       },
//       {
//         path: "firmaccount",
//         name: "FirmAccount",
//         component: () => import("@/views/FinanceManage/FirmAccount"),
//       },
//       {
//         path: "boxaccount",
//         name: "BoxAccount",
//         component: () => import("@/views/FinanceManage/BoxAccount"),
//       },
//     ],
//   },
// ],

export const filtersRouter = (routes) => {
  let dynamicRoutes = []
  routes.forEach((item) => {
    if (item.children) {
      let children = [];
      item.children.forEach((item1) => {
        children.push({
          path: item1.url,
          name: item1.perms,
          meta: {
            title: item1.menuName,
          },
          component: () => import(`@/views/${item.perms}/${item1.perms}`),
        });
      });
      dynamicRoutes.push({
        path: item.url,
        name: item.perms,
        meta: {
          title: item.menuName,
          icon: item.icon,
        },
        component: () => import(`@/views/Layout`),
        children,
      });
    } else {
      dynamicRoutes.push({
        path: item.url,
        name: item.perms,
        meta: {
          title: item.menuName,
          icon: item.icon,
        },
        component: () => import(`@/views/${item.perms}`),
      });
    }
  });
  return dynamicRoutes
};
